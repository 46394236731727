import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { IProducts } from "../../models/IProducts";

export const getProducts = createAsyncThunk<IProducts[]>(
    "products/getProducts",
    async () => {
        const products = await axios.get<IProducts[]>(`${process.env.REACT_APP_BACKEND_URL}/products/`);
    
        return products.data;
    }
    );