import { AppBar, Toolbar, Typography, Badge, Drawer, IconButton, useMediaQuery, Button, Menu as MuiMenu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import "./Menu.component.scss";
import { useEffect, useState } from "react";
import { ShoppingCart } from "../shopping-cart/ShoppingCart.component";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { removeFromCart } from "../../store/features/shoppingCart/shoppingCartSlice";
import { useNavigate } from "react-router-dom";
import theme from "../../shared/theme/themes";
import logo from "../../shared/images/LogoColor.png";

export const Menu = () => {
  const navigate = useNavigate();

  const scrollToSection = (id: string) => {
    navigate("/");
    document.getElementById(id)?.scrollIntoView({ behavior: "smooth", block: "start" });
    setMenuOpen(false);
  };
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [language, setLanguage] = useState("RO");
  const dispatch = useDispatch();
  const cartItems = useSelector((state: RootState) => state.shoppingCart.items);
  const totalQuantity = useSelector((state: RootState) => state.shoppingCart.totalQuantity);
  const totalPrice = useSelector((state: RootState) => state.shoppingCart.totalPrice);
  const lastAddedProduct = useSelector((state: RootState) => state.shoppingCart.lastAddedProduct);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleRemoveItem = (id: string) => {
    dispatch(removeFromCart(id));
  };

  useEffect(() => {
    if (lastAddedProduct) {
      setDrawerOpen(true);
    }
  }, [lastAddedProduct]);

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleLanguageSwitch = (lang: string) => {
    setLanguage(lang);
    // You can also add logic to change the language of your app here
  };

  const handleMenuOpen = () => {
    setMenuOpen(true);
  };

  const handleMenuClose = () => {
    setMenuOpen(false)
  };

  return (
    <div className="menu">
      <AppBar position="sticky" className={`app-bar ${hidden ? 'hidden' : ''}`}>
        <Toolbar>
          {isMobile ? (
            // Mobile View
            <>
              <img src={logo} alt="Logo" className="logo-mobile" />
              <Drawer open={Boolean(menuOpen)} onClose={handleMenuClose}>
                <MenuItem onClick={() => scrollToSection("home")}>Acasă</MenuItem>
                <MenuItem onClick={() => scrollToSection("about")}>Despre Zbor</MenuItem>
                <MenuItem onClick={() => scrollToSection("gallery")}>Galerie Media</MenuItem>
                <MenuItem onClick={() => navigate("/voucher")}>Vouchere</MenuItem>
                <MenuItem onClick={() => scrollToSection("contact")}>Contact</MenuItem>
              </Drawer>
              <div style={{ flexGrow: 1 }} /> {/* Pushes elements to the right */}
              <div className="left-container" style={{ display: "flex", alignItems: "center" }}>
                <div className="basket-mobile">
                  <Badge badgeContent={totalQuantity} color="primary">
                    <IconButton color="inherit" onClick={toggleDrawer(true)}>
                      <ShoppingCartIcon />
                    </IconButton>
                  </Badge>
                  <Typography variant="body1" sx={{ fontSize: "0.8rem" }}>
                    {totalPrice} lei
                  </Typography>
                </div>
              </div>
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={handleMenuOpen}>
                <MenuIcon />
              </IconButton>
            </>
          ) : (
            // Desktop View
            <>
              <div className="navLinks">
                <a href="#home" onClick={() => scrollToSection("home")} className="navLink">
                  Acasă
                </a>
                <a href="#about" onClick={() => scrollToSection("about")} className="navLink">
                  Despre Zbor
                </a>
                <a href="#gallery" onClick={() => scrollToSection("gallery")} className="navLink">
                  Galerie Media
                </a>
                <a href="" onClick={() => navigate("/voucher")} className="navLink">
                  Vouchere
                </a>
                <a href="#contact" onClick={() => scrollToSection("contact")} className="navLink">
                  Contact
                </a>
              </div>
              <div className="basket">
                <Badge badgeContent={totalQuantity} color="primary">
                  <IconButton color="inherit" onClick={toggleDrawer(true)}>
                    <ShoppingCartIcon />
                  </IconButton>
                </Badge>
                <Typography variant="body1">{totalPrice} lei</Typography>
              </div>
              <div className="languageSwitch">
                <span
                  onClick={() => handleLanguageSwitch("RO")}
                  style={{ cursor: "pointer", fontWeight: language === "RO" ? "bold" : "normal" }}
                >
                  RO
                </span>
                {" | "}
                <span
                  onClick={() => handleLanguageSwitch("EN")}
                  style={{ cursor: "pointer", fontWeight: language === "EN" ? "bold" : "normal" }}
                >
                  EN
                </span>
              </div>
            </>
          )}
        </Toolbar>
      </AppBar>
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <ShoppingCart cartItems={cartItems} onRemoveItem={handleRemoveItem} onCloseDrawer={handleCloseDrawer} />
      </Drawer>
    </div>
  );
};
