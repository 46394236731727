import { useDispatch, useSelector } from "react-redux";
import { Footer } from "../footer/Footer.component";
import { Hero } from "../hero/Hero.component";
import { Menu } from "../menu/Menu.component";
import "./Products.component.scss";
import { AppDispatch, RootState } from "../../store/store";
import { useEffect } from "react";
import { getProducts } from "../../store/actions/getProducts.actions";
import {
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
  Box,
} from "@mui/material";
import bunlocInitiere from "../../shared/images/bunloc-initiere.webp";
import bonlocAvansat from "../../shared/images/bunloc-avansat.webp";
import postavaruInitiere from "../../shared/images/postavaru-initiere.webp";
import postavaruAvansat from "../../shared/images/postavaru-avansat.jpeg";
import { IProducts } from "../../models/IProducts";
import { addToCart } from "../../store/features/shoppingCart/shoppingCartSlice";

export const Products = () => {
  const dispatch = useDispatch<AppDispatch>();

  const products = useSelector(
    (state: RootState) => state.productsCollection.products
  );

  const loading = useSelector(
    (state: RootState) => state.productsCollection.loading
  );

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const images = [
    bunlocInitiere,
    bonlocAvansat,
    postavaruInitiere,
    postavaruAvansat,
  ];

  const getRandomImage = () => {
    return images[Math.floor(Math.random() * images.length)];
  };

  const handleAddToCart = (product: IProducts) => {
    dispatch(addToCart(product));
  };
  return (
    <>
      <Menu />
      <Hero />
      <Box sx={{ padding: "8rem " }}>
        {loading === "pending" ? (
          <Typography variant="h6">Se încarcă produsele...</Typography>
        ) : (
          <Grid container spacing={4}>
            {products.map((product) => (
              <Grid item xs={12} sm={6} md={3} key={product._id}>
                <Card>
                  <CardMedia
                    component="img"
                    height="200"
                    image={getRandomImage()}
                    alt={product.name}
                  />
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {product.name}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      {product.price} LEI
                    </Typography>
                    {product.description.map((desc, index) => (
                      <Typography
                        key={index}
                        variant="subtitle2"
                        className="card-back-description"
                      >
                        <span className={`icon ${desc.icon}`} /> {desc.text}
                      </Typography>
                    ))}
                  </CardContent>
                  <CardActions>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button size="large" color="primary" variant="contained" onClick={() => handleAddToCart(product)}>
                        Adaugă în coș
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Footer />
    </>
  );
};
