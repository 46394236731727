import { Typography, Grid, Paper, TextField, FormControlLabel, Checkbox, Button, Container } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import './Contact.component.scss'

export const Contact = () => {
  return (
    <Container>
    <div className="contact-section" id="contact">
      <Typography variant="h4" className="section-title">Contact</Typography>
      <Grid container spacing={4} className="contact-container">
      <Grid item xs={12} md={6} className="contact-grid-item">
          <div className="contact-info">
            <Typography variant="h6">Informații și rezervări</Typography>
            <div className="info-item">
              <PhoneIcon />
              <Typography>+40735628164 Ovidiu</Typography>
            </div>
            <div className="info-item">
              <PhoneIcon />
              <Typography>+40741569472 Sabina</Typography>
            </div>
            <div className="info-item">
              <EmailIcon />
              <Typography>kronpara@gmail.com</Typography>
            </div>
            <Typography variant="body1" className="schedule">
              Program: 8:00 - 18:00
              <br />
              *În funcție de condițiile meteo și programul de funcționare a instalațiilor de urcare
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="contact-form">
            <Typography variant="h6">Formular contact</Typography>
            <form noValidate autoComplete="off">
              <TextField fullWidth margin="normal" label="Mesaj" variant="outlined" />
              <TextField fullWidth margin="normal" label="Nume" variant="outlined" />
              <TextField fullWidth margin="normal" label="Prenume" variant="outlined" />
              <TextField fullWidth margin="normal" label="Email" variant="outlined" />
              <TextField fullWidth margin="normal" label="Telefon" variant="outlined" />
              <FormControlLabel
                control={<Checkbox name="not-robot" />}
                label="Nu sunt robot"
              />
              <Button variant="contained" color="primary" type="submit">
                Trimite
              </Button>
            </form>
          </div>
        </Grid>
      </Grid>
    </div>
    </Container>
  )
}
