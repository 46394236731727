import {
  Box,
  TextField,
  Typography,
  Button,
  AppBar,
  Toolbar,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
  Alert,
  Backdrop,
  Snackbar,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import logo from "../../shared/images/LogoColor.png";
import { TermsAndConditions } from "../termeni-conditii/TermsAndConditions.component";
import { Footer } from "../footer/Footer.component";
import { initiatePayment } from "../../store/features/payment/paymentSlice";
import { fetchOrder } from "../../store/features/orders/ordersSlice";
import { clearCart } from "../../store/features/shoppingCart/shoppingCartSlice";

export const CompleteOrder = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const shoppingCartItems = useSelector((state: RootState) => state.shoppingCart.items);
  const paymentLoading = useSelector((state: RootState) => state.payment.loading);

  const paymentState = useSelector((state: RootState) => state.payment);
  const totalPrice = useSelector((state: RootState) => state.shoppingCart.totalPrice);
  const totalQuantity = useSelector((state: RootState) => state.shoppingCart.totalQuantity);
  const order = useSelector((state: RootState) => state.orders.order);
  const orderLoading = useSelector((state: RootState) => state.orders.loading);
  const orderError = useSelector((state: RootState) => state.orders.error);

  const [activeStep, setActiveStep] = useState(0);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [waitingPaymentResponse, setWaitingPaymenntResponse] = useState(false);

  // fetch orders if url has orderId in
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const orderId = query.get("orderId");
    if (orderId) {
      dispatch(fetchOrder(orderId));
      dispatch(clearCart());
      setActiveStep(2);
    }
  }, [location.search, dispatch]);

  const [formData, setFormData] = useState({
    nume: "",
    prenume: "",
    adresa: "",
    telefon: "",
    email: "",
    persoanaJuridica: false,
    numeCompania: "",
    CUI: "",
    numarRegistruComertului: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e: any) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleTermsChange = (e: any) => {
    setTermsAccepted(e.target.checked);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handlePayment = async () => {
    setWaitingPaymenntResponse(true);

    // Prepare data for payment provider
    const orderId = new Date().getTime().toString(); // attach a uuid for the orderID
    const amount = totalPrice;
    const currency = "RON";

    const customerInfo = {
      firstName: formData.nume,
      lastName: formData.prenume,
      address: formData.adresa,
      email: formData.email,
      phone: formData.telefon,
    };

    const paymentData = {
      orderId,
      amount,
      currency,
      customerInfo,
      shoppingCartItems,
    };

    try {
      const response = await dispatch(initiatePayment(paymentData)).unwrap();
      console.log("Payment response frontend: ", response);
      // console.log("Payment url: ", paymentURL);

      // Open a new tab with the payment URL
      const paymentUrl = response.paymentURL;
      // const paymentUrl = paymentURL;
      if (paymentUrl) {
        window.open(paymentUrl, "_blank");
        handleNext();
      } else {
        throw new Error("Invalid payment URL");
      }
    } catch (error) {
      console.error("Payment error: ", error);
      setAlertMessage("Tranzacție nereușită, vă rugăm să reîncercați.");
      setAlertOpen(true);
    }
  };

  const steps = [
    {
      label: "Informații Client",
      content: (
        <Box component="form" sx={{ mt: 2 }}>
          <TextField label="Nume" name="nume" fullWidth margin="normal" value={formData.nume} onChange={handleChange} />
          <TextField label="Prenume" name="prenume" fullWidth margin="normal" value={formData.prenume} onChange={handleChange} />
          <TextField label="Adresa" name="adresa" fullWidth margin="normal" value={formData.adresa} onChange={handleChange} />
          <TextField label="Telefon" name="telefon" fullWidth margin="normal" value={formData.telefon} onChange={handleChange} />
          <TextField label="Email" name="email" fullWidth margin="normal" value={formData.email} onChange={handleChange} />
          <FormControlLabel
            control={<Checkbox checked={formData.persoanaJuridica} onChange={handleCheckboxChange} name="persoanaJuridica" />}
            label="Persoana juridică"
          />
          {formData.persoanaJuridica && (
            <>
              <TextField
                label="Nume Compania"
                name="numeCompania"
                fullWidth
                margin="normal"
                value={formData.numeCompania}
                onChange={handleChange}
              />
              <TextField label="CUI" name="CUI" fullWidth margin="normal" value={formData.CUI} onChange={handleChange} />
              <TextField
                label="Numar Registru Comertului"
                name="numarRegistruComertului"
                fullWidth
                margin="normal"
                value={formData.numarRegistruComertului}
                onChange={handleChange}
              />
            </>
          )}
        </Box>
      ),
    },
    {
      label: "Sumar Comandă",
      content: (
        <Box sx={{ mt: 2 }}>
          <Typography>Nume: {formData.nume}</Typography>
          <Typography>Prenume: {formData.prenume}</Typography>
          <Typography>Adresa: {formData.adresa}</Typography>
          <Typography>Telefon: {formData.telefon}</Typography>
          <Typography>Email: {formData.email}</Typography>

          {/* add the list of products in the shopping cart */}
          <Box sx={{ mt: 2 }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Produs</TableCell>
                    <TableCell align="right">Preț</TableCell>
                    <TableCell align="right">Cantitate</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {shoppingCartItems.map((item) => (
                    <TableRow key={item._id}>
                      <TableCell>{item.name}</TableCell>
                      <TableCell align="right">{item.price} LEI</TableCell>
                      <TableCell align="right">{item.quantity}</TableCell>
                      <TableCell align="right">{item.price} LEI</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell rowSpan={3} />
                    <TableCell colSpan={2}>
                      <Typography variant="h6">Total</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="h6">{totalPrice} LEI</Typography>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* termeni si conditii */}
          <FormControlLabel
            control={<Checkbox checked={termsAccepted} onChange={handleTermsChange} />}
            label={
              <div className="termeni">
                Am citit și accept <Button onClick={handleModalOpen}>termenii și condițiile de plată KronPara</Button>
              </div>
            }
          />
          <Button variant="contained" onClick={handlePayment} disabled={!termsAccepted} color="secondary" sx={{ mt: 4, mb: 8 }}>
            {paymentLoading === "pending" ? <CircularProgress size={24} /> : "Plătește"}
          </Button>
        </Box>
      ),
    },
    {
      // after the response from netopia come back move to this step o show an error if the payment was not processed
      label: "Confirmare Comandă",
      content: (
        <Box sx={{ mt: 2 }}>
          {orderError && (
            <Typography variant="h6" gutterBottom>
              Eroare la procesarea comenzii
            </Typography>
          )}
          {orderLoading && (
            <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={waitingPaymentResponse}>
              <CircularProgress color="inherit" />
            </Backdrop>
          )}
          {order && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Comanda dvs. a fost plasată cu succes!
              </Typography>
              <Typography variant="body1">
                Vă mulțumim pentru comanda dvs. Urmăriți-vă e-mailul pentru confirmarea plății și pentru detalii despre cum să descărcați
                voucherul și factura.
              </Typography>
              {/* Display order information */}
              <Box sx={{ mt: 2 }}>
                <Typography variant="body1">Numar Comanda: {order.orderId}</Typography>
                <Typography variant="body1">
                  Suma: {order.amount} {order.currency}
                </Typography>
                <Typography variant="body1">
                  Client: {order.customerInfo.firstName} {order.customerInfo.lastName}
                </Typography>
              </Box>
            </Box>
          )}

          {/* Display payment response data */}

          <Button variant="contained" color="primary" onClick={() => navigate("/")} sx={{ mt: 2 }}>
            Înapoi la pagina principală
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <AppBar position="static">
        <Toolbar>
          <Button color="inherit" onClick={() => navigate("/")}>
            Înapoi la pagina principală
          </Button>
          <img src={logo} alt="Logo" style={{ marginLeft: "auto", height: 50 }} />
        </Toolbar>
      </AppBar>
      <Box sx={{ maxWidth: 550, mx: "auto", mt: 4 }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>
                {step.content}
                {
                  activeStep === 0 ?
                    <Box sx={{ mb: 2 }}>
                      <div>
                        <Button variant="contained" onClick={handleNext} sx={{ mt: 1, mr: 1 }} disabled={activeStep === steps.length - 1}>
                          Continuă
                        </Button>
                        <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }} disabled={activeStep === steps.length - 1}>
                          Înapoi
                        </Button>
                      </div>
                    </Box> : activeStep === 1 ?
                    <Box sx={{ mb: 2 }}>
                    <div>
                      <Button onClick={handleBack} sx={{ mt: 1, mr: 1 }} disabled={activeStep === steps.length - 1}>
                        Înapoi
                      </Button>
                    </div>
                  </Box> : null
                  }
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>Toți pașii au fost finalizați</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Resetează
            </Button>
          </Paper>
        )}
        <TermsAndConditions open={modalOpen} handleClose={handleModalClose} />
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
          <Alert onClose={handleAlertClose} severity="error" sx={{ width: "100%" }}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={waitingPaymentResponse}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>
      <Footer />
    </Box>
  );
};
