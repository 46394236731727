import logo from '../../shared/images/LogoColor.png'
import headerImg from '../../shared/images/bg2.webp'
import "./Hero.component.scss"
export const Hero = () => {
  return (
    <div className="hero-home" id="home">
    <div className="hero-header-container">        
      <img src={headerImg} alt="Header" className="hero-header-image" />
      <div className="overlay">
        <img src={logo} alt="Logo" className="logo" />
        <h1>Intra in posesia unui voucher<br /> si hai sa zburam!</h1>
          <p>Distractie si adrenalina</p>
      </div>
    </div>
  </div>
  )
}
