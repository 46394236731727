import "./Home.component.scss";
import { Header } from "../header/Header.component";
import { Menu } from "../menu/Menu.component";
import OfferCards from "../offer-cards/OfferCards.component";
import { InfoClient } from "../info-client/InfoClient.component";
import { AboutFly } from "../about-fly/AboutFly.component";
import MediaCarousel from "../media-carousel/MediaCarousel.component";
import { ImageSection } from "../image-section/ImageSection.component";
import { Contact } from "../contact/Contact.component";
import { Footer } from "../footer/Footer.component";
import { Chat } from "../chat/Chat.component";
import { MainText } from "../main-text/MainText.component";
import { Box, Container, Grid, useMediaQuery } from "@mui/material";
import theme from "../../shared/theme/themes";

const Home = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Menu />
      <Container 
      maxWidth="xl" 
      sx={{ 
        padding: 0, 
        margin: 0, 
        width: '100%' 
      }}
    >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Header />
          </Grid>
          <Grid item xs={12}>
            <OfferCards />
          </Grid>
          <Grid item xs={12}>
            <MainText />
          </Grid>
          <Grid item xs={12}>
            <InfoClient />
          </Grid>
          <Grid item xs={12}>
            <AboutFly />
          </Grid>
          {!isMobile && (
            <>
              <Grid item xs={12}>
                <MediaCarousel />
              </Grid>
              <Grid item xs={12}>
                <ImageSection />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Contact />
          </Grid>
          <Grid item xs={12}>
            <Footer />
          </Grid>
        </Grid>
        <Chat />
      </Container>
    </>
  );
};

export default Home;
