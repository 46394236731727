import { Modal, Box, Typography, Button } from '@mui/material';


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };
interface Props {
    open: boolean,
    handleClose: ()=> void
}
export const TermsAndConditions: React.FC<Props>  = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2">
          Termeni și condiții de plată KronPara
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2 }}>
          {/* Add your terms and conditions text here */}
          Acești termeni și condiții reglementează utilizarea serviciilor noastre...
        </Typography>
        <Button onClick={handleClose} sx={{ mt: 2 }}>
          Închide
        </Button>
      </Box>
    </Modal>
  )
}
